import Joi from "joi";

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    name: Joi.string()
        .min(3)
        .max(255)
        .trim()
        .required(),
    info: Joi.string()
        .max(1000)
        .trim()
        .allow(null, ""),
    enabled: Joi.boolean()
        .optional()
});

export const create = Joi.object({
    entity: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    name: Joi.string()
        .min(3)
        .max(255)
        .trim()
        .required(),
    info: Joi.string()
        .max(1000)
        .trim()
        .allow(null, ""),
});

export const upload = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required()
});

