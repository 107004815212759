<template>
    <Dialog :title="$t('video.create.title')" :saveProgress="saveProgress" :open="open" @cancel="$emit('cancel')" @save="save()">
        <v-text-field
            v-model="form.name"
            :label="$t('video.form.name')"
            :error-messages="errors.name"
            :hide-details="!errors.name"
            :disabled="saveProgress"
            @change="validate('name')"
            outlined
            prepend-inner-icon="mdi-filmstrip-box-multiple"
        />
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";

import validator from "../../plugins/validator";
import * as videoValidation from "../../api/video/validation";

export default {
    components: {
        Dialog
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        entity: {
            type: String,
            default: null
        }
    },

    data: () => ({
        form: {
            name: null
        },

        errors: {
            name: null
        },

        show: true,
        saveProgress: false
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                videoValidation.create,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if(!this.validate()) return;
            if(this.entity) this.form.entity = this.entity;

            this.saveProgress = true;
            let res = await this.$store.dispatch("api/video/create", this.form);
            this.saveProgress = false;

            if (res.status == 200) {
                this.$emit("save");
                this.$emit("cancel");
            }
        }
    }
};
</script>
